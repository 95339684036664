<template>
  <div class="products">
    <div class="product-body">
      <b-alert v-if="!urunler.length" show variant="warning" class="text-center m-2">
        <h5>Bilgi</h5>
        <hr />
        <p>Gösterilecek veri yok</p>
      </b-alert>
      <div v-else>
        <draggable tag="b-row" v-model="urunler" handle=".draggable-sort-item" @end="handlerSiraChange(urunler)">
          <b-col cols="12" md="4" v-for="(urun, index) in urunler" :key="index">
            <b-card no-body class="rounded-0 py-3 px-2 m-2">
              <b-card-text class="d-flex justify-content-start align-items-center">
                <div class="draggable-sort-item" style="cursor: pointer">
                  <i class="fad fa-sort fa-2x"></i>
                </div>
                <div class="px-3 py-1 font-weight-bold text-primary">
                  {{ index + 1 }}
                </div>
                <div>{{ urun.baslik }}</div>
              </b-card-text>
            </b-card>
          </b-col>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, watch, ref, toRefs } from '@vue/composition-api';

import store from '@/store';
import draggable from 'vuedraggable';
import { useRouter } from '@/libs/utils';
export default defineComponent({
  components: { draggable },
  props: {},
  setup(props, context) {
    const expo = {};
    const { router, route } = useRouter();
    expo.defaultDil = computed(() => store.getters.getDilAyarlari.varsayilan);
    expo.urunler = ref([]);
    expo.count = ref(0);

    expo.handlerFetchAllData = async () => {
      context.emit('show', true);
      context.emit('pageTitle', 'Ürün Sıralama');

      await store.dispatch('urunSiralamaListele', { urun_tipi: 'tur', locale: expo.defaultDil.value }).then((res) => {
        expo.urunler.value = res.data.data;
        expo.count.value = res.data.data.length;
        context.emit('total', `${expo.count.value} kayıttan ${expo.urunler.value.length} `);
        context.emit('show', false);
      });
    };

    expo.handlerFetchAllData();

    expo.handlerSiraChange = (event) => {
      store
        .dispatch('urunSiralamaGuncelle', { product: event })
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Sıra güncelleme başarılı.', {
              position: 'bottom-left',
            });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message, {
              position: 'bottom-left',
            });
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style scoped></style>
